// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // Supported languages
    resources: {
      en: {
        translation: {
          'home':'home',
          hello: 'Hello',
          welcome: 'Welcome to my app',
          explore:'Explore',
          church_of_God1: "Ministering",
          church_of_God2: "Ministering",
          name_Church1:"TORCH",
          name_Church2:"TORCH",
          textBanner1:"Fulfilling the command of the Lord Jesus Christ",
          textBanner2:"Fulfilling the command of the Lord Jesus Christ",
          next_event:"Next Event",
          join_now:"Join Now",
          torch:'TORCH',
          power:'POWER',
          activation:'ACTIVATION',
          figth:'FIGTH',
          kingdom:'KINGDOM',
          conection:'CONNECTION',
          contact_us:'CONTACT US',
          testimonials:'TESTIMONIALS',
          inicio: 'Inicio',
          'About Us':'About Us',
          Romanos_15_20: 'It has always been my ambition to preach the gospel where Christ was not known, so that I would not be building on someone else’s foundation. Romans 15:20',
          history_5_title: 'Call',
          history_5_text: 'Wilson and María Cristina Zea are called by God as pastors in the city of Bogotá D.C. Their ministry has served for the training of workers.',
          history_4_title: 'CEMTRA',
          history_4_text: 'While at a youth camp, God instructs them to establish a Transcultural Missionary Training Center (CEMTRA) as a School of Missions.',
          history_3_title: 'Location',
          history_3_text: 'God leads them to the city of Zipaquirá (Cundinamarca), Colombia, to pastor in this place.',
          history_2_title: 'Expansion',
          history_2_text: 'After seven promotions in CEMTRA and several national and international deployments, God expands their vision so that workers are sent through their own missionary agency.',
          history_1_title: 'TORCH Ministry',
          history_1_text: 'Launch of the TORCH Missionary Agency.',
          title_event1: 'Spiritual Retreat (children)',
          shorttext_event1:'Short',
          eventdate_event1: '11 May 2024',
          eventtime_event1: 'Saturday - Sunday',
          location_event1: 'CEMTRA',
          title_event2: 'Spiritual Retreat (children)',
          shorttext_event2:'Short',
          eventdate_event2: '11 May 2024',
          eventtime_event2: 'Saturday - Sunday',
          location_event2: 'CEMTRA',
          textexplore1:"",
          textexplore2:"",
          textexplore3:"",
          textexplore4:"",
          textexplore5:"",
        },
      },
      es: {
        translation: {
          'home':'Inicio',
          hello: 'Hola',
          welcome: 'Bienvenido a mi aplicación',
          explore:'Explorar',
          church_of_God1: "Ministrando",
          church_of_God2: "Ministrando",
          name_Church1:"TORCH",
          name_Church2:"TORCH",
          textBanner1: "Cumpliendo con el mandato del Señor Jesucristo",
          textBanner2: "Cumpliendo con el mandato del Señor Jesucristo",
          next_event:"Próximo Evento",
          join_now:"Join Now",
          torch:'TORCH',
          power:'PODER',
          activation:'ACTIVACIÓN',
          figth:'COMBATE',
          kingdom:'REINO',
          conection:'CONEXIONES',
          contact_us:'CONTACTENOS',
          testimonials:'TESTIMONIOS',
          Inicio: 'Home',
          'About Us':'Historia',
          Romanos_15_20: 'Y de esta manera me esforcé a predicar el evangelio, no donde Cristo ya hubiese sido nombrado, para no edificar sobre fundamento ajeno. Romanos 15:20',
          history_5_title: 'Llamado',
          history_5_text: 'Wilson y María Cristina Zea son llamados por Dios como pastores en la ciudad de Bogotá D.C. Su ministerio ha servido para la formación de obreros.',
          history_4_title: 'CEMTRA',
          history_4_text:'Estando en un campamento de jóvenes, Dios les da la instrucción a que levanten un Centro de Entrenamiento Misionero Transcultural (CEMTRA) como Escuela de Misiones',
          history_3_title:'Locación',
          history_3_text:'Dios los lleva a la ciudad de Zipaquirá (Cundinamarca)-Colombia a pastorear en este lugar.',
          history_2_title:'Ampliación',
          history_2_text:'Luego de siete promociones en CEMTRA y de varios envíos nacionales e internacionales, Dios les amplía la visión para que los obreros sean enviados a través de su propia agencia misionera',
          history_1_title:'Ministerio TORCH',
          history_1_text:'Lanzamiento de la Agencia Misionera TORCH.',
          title_event1: 'Taller parejas',
          shorttext_event1:'“Nuestra mejor estación para extender”',
          eventdate_event1: '20 April 2024',
          eventtime_event1: 'Sábado - Domingo',
          location_event1: 'CEMTRA',
          title_event2: 'Retiro Espiritual (niños)',
          shorttext_event2:'Hola',
          eventdate_event2: '12 April 2024',
          eventtime_event2: 'Sábado - Domingo',
          location_event2: 'CEMTRA',
          textexplore1:"Súmate",
          textexplore2:"Poder",
          textexplore3:"Acciona",
          textexplore4:"Combate ",
          textexplore5:"Vive",

        },
      },
    },
    lng: 'es',
    //fallbackLng: 'es', // Default language
    detection: {
      order: ['navigator', 'localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
